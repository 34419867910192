import axios from "axios";
import { createContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useAuthContext } from "../index.context";
import { data } from "jquery";

export const TrackOrderContext = createContext();

function TrackOrderProvider({ children }) {
    const { API, authorizeToken } = useAuthContext();
    const [locationData, setLocationData] = useState(null);
    const [pincodeError, setPincodeError] = useState(false);
    const [wayBillData, setWayBillData] = useState(null);
    const [disable, setDisable] = useState();
    const [trackingDetail, setTrackingDetail] = useState(null);

    // Location Finder 
    const addLocationFinder = async (pincode) => {
        setDisable(true)
        try {
            const res = await axios.post(`${API}/order/locationFinder`, { pincode })
            setLocationData(res?.data)

            if (res?.data?.IsError === false) {
                setPincodeError(false)
            } else setPincodeError(true)
        } catch (error) {
            console.log(error)
        } finally {
            setDisable(false);
        }
    }

    // create WayBill 
    const createWayBill = async (data) => {
        setDisable(true);

        const { createWayBillData, orderId, products } = data;
        const productsArray = Array.isArray(products) ? products : [products];
        const transformedData = {
            ...createWayBillData,
            orderId,
            products: productsArray
        };
        try {
            const res = await axios.post(`${API}/wayBill/create`, transformedData, {
                headers: { 'Authorization': `Bearer ${authorizeToken}` }
            });
            setWayBillData(res?.data);
            // toast.success("successfully creating waybill");
            setTimeout(() => { window.location.href = "/thankyou" }, 1000)

        } catch (error) {

            console.log("Error in request:", error);
            console.log("Error response:", error?.response?.data);
            toast.error(error?.response?.data?.message || "Error creating waybill");

            if (error.response) {
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
                console.error('Error response headers:', error.response.headers);


            } else if (error.request) {
                console.error('Error request:', error.request);

            } else {
                console.error('Error message:', error.message);
            }
        } finally {
            setDisable(false);
        }
    };

    // Tracking Shipment
    const TrackOrder = async (data) => {
        try {
            const res = await axios.post(`${API}/wayBill/TrackingShipment`, data, {
                headers: { 'Authorization': `Bearer ${authorizeToken}` }

            });
            setTrackingDetail(res?.data)
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <TrackOrderContext.Provider value={{ addLocationFinder, locationData, pincodeError, setPincodeError, createWayBill, TrackOrder, wayBillData, trackingDetail, disable }}>
            {children}
        </TrackOrderContext.Provider>
    )
}
export default TrackOrderProvider;